import Backbone from 'backbone'
import _ from 'underscore'
import { ajax } from 'util/ajax'

import {
    abbreviateName,
    formatPhone,
    profileImageSrc
} from 'util/twistle'

import { isTeam } from 'app/helpers/account_helpers'

import { betterFormatDate } from 'util/date'

// model encapsulating a user account
const Account = Backbone.Model.extend({
    profile: {},
    url: function(){return "/v2/account/ViewFull";},
    parse: function(resp) {
        return resp.profile;
    },
    idAttribute: "username",
    initialize: function(attribs) {
        this.bind('change', this.updateAttributes);
        this.updateAttributes();
    },

    updateAttributes: function() {
        this.attributes.isMale = this.attributes.gender === "m";
        this.attributes.isFemale = this.attributes.gender === "f";
        this._setPatientProviderAttributes()
        this.attributes.rawPhone = this.attributes.phone;
        this.attributes.phone = formatPhone(this.attributes.phone);

        this.attributes.profileimageurl = profileImageSrc({ username: this.get("username"), size: "thm", seqnum: (this.get("photo") || false), id: (this.get("id") || false), accountType: this.get("accounttype") });
        this.attributes.profilefullimageurl = profileImageSrc({ username: this.get("username"), size: "prf", seqnum: (this.get("photo") || false), id: (this.get("id") || false), accountType: this.get("accounttype") });
        if (this.get("formalname")) {
            this.attributes.abbreviatedName = abbreviateName(this.get("formalname"));
        }
        else {
            this.attributes.abbreviatedName = this.get("shortname");
        }
        _.each(this.attributes.orgs, function(org) {
            org.client_name = "Patients";

            if(org.has_clients)
            {
                org.client_abbreviation = org.abbreviation + "-P";
                org.staff_abbreviation = org.abbreviation + "-S";
            }
            else
            {
                org.client_abbreviation = org.abbreviation;
                org.staff_abbreviation = org.abbreviation;
            }

            if(org.temporary && org.role === "certified_builder"){
                org.is_temporary_certified_builder = true;
            }
        });

        if(this.attributes.dob && this.attributes.dob.length > 0)
        {
            var dobDate = new Date(Date.parse(this.attributes.dob));

            this.attributes.dob_month = betterFormatDate(dobDate, "mm");
            this.attributes.dob_day = betterFormatDate(dobDate, "dd");
            this.attributes.dob_year = dobDate.getFullYear();
        }

        var roleData = this.attributes.roles,
            processedRoles = [];
        _.each(roleData || [], function(role){
            processedRoles.push(new Account(role).toJSON());
        });
        this.attributes.roles = processedRoles;
        var all_orgs = (this.attributes.orgs || []).concat((this.attributes.client_orgs || [])).filter(x => x);
        this.attributes.all_orgs = _.uniq(all_orgs, org => org.id);
    },

    setSelectedRole: function(selectedRole)
    {
        this.attributes.current_as_role_user = selectedRole;
        App.trigger("app:account_updated");
    },

    getBetaSetting: function(setting) {
        return this.getProfileSetting(setting);
    },

    getProfileSetting: function(setting, defaultValue) {
        var profile = this.get("profile");
        if (profile && profile[setting]){
            return profile[setting];
        }
        return defaultValue || "";
    },
    setProfileSetting: function (setting, value, saveToServerProfile, callback) {
        var profile = this.get("profile");
        profile[setting] = value;
        if (saveToServerProfile) {
            ajax.request("/profile/Update", {infoname: setting, infocontent: value}, [], callback);
        }
    },

    shouldShowSpotlightHelp: function(spotlightType){
        let profileSettingName = "spotlight_help_viewed_list",
            currentListValue = this.getProfileSetting(profileSettingName),
            currentList = (currentListValue?.length > 0) ? currentListValue.split(",") : [];
        if(currentList.indexOf(spotlightType) === -1){
            currentList.push(spotlightType);
            this.setProfileSetting(profileSettingName, currentList.join(","), true);
            return true;
        }
        return false;
    },

    canSeeAnalyticsForOrg: function(org) {
        const self = this;
        org = org || self.getDefaultOrg();
        return org.analytics
    },

    canSeePathwayInsightsForOrg: function(org) {
        const self = this;
        org = org || self.getDefaultOrg();
        if (self.canSeeAnalyticsForOrg(org)) {
            return org.extended_props.show_pathway_insights;
        }
        return false;
    },

    canManageBulkIngest: function(org){
        const self = this;
        org = org || self.getDefaultOrg();
        return self.get("user_admin") || (org && self.isProvider(org) && org.extended_props?.show_bulk_ingest);
    },

    orgRequiresDOBForInvite: function(org) {
        return (org &&
                org.extended_props &&
                org.extended_props.allow_simple_verification &&
                org.extended_props.simple_verification_validation_method === "dob");
    },

    orgMinimumAge: function(org) {
        const self = this;
        org = org || self.getDefaultOrg();
        const customMin = parseInt(org.extended_props?.min_age_years_override, 10);
        if(customMin > -1){
            return customMin
        }
        return App.config.get("minimum_age");
    },

    orgRequiresPatientIDForInvite: function(org) {
        return (org &&
                org.extended_props &&
                org.extended_props.allow_simple_verification &&
                org.extended_props.simple_verification_validation_method === "client_identifier");
    },

    orgDefaultTimeZone: function(orgId) {
        var org = this.getOrgById(orgId);
        if (org && org.extended_props) {
            return org.extended_props.default_timezone;
        }
    },

    // return true if this account is a healthcare provider
    // default to current selected org if not passed in. Supports either a full org object, or just the id, or undefined
    isProvider: function(org) {
        org = org || this.getDefaultOrg() || null;
        return !!this.attributes.orgs?.find(o => (o === org || o.id === org));
    },

    // same idea but for patient(s)
    isPatient: function(org) {
        org = org || this.getDefaultOrg() || null;
        return !!this.attributes.client_orgs?.find(o => (o === org || o.id === org));
    },

    isClient: function() {
        return this.isPatient();
    },

    // Return true if this account does not have a patient relationship with the current org AND is a care giver to at least one account
    isCustodian: function(org) {
        org = org || this.getDefaultOrg() || null;
        return !this.get('relationships_info').some(ri => ri.thru_organization == org.id && ri.relationship === 'patient') && this.get('custodees').length > 0
    },

    isTeam: function() {
        return isTeam(this);
    },

    currentRoles: function() {
        let org = this.getDefaultOrg();
        return this.attributes.roles.filter(role => role.relationships_info.some(ri => ri.thru_organization == org.id));
    },

    getOrgById: function(org_id) {
        var i;
        org_id = parseInt(org_id, 10) || -1;
        if (this.attributes.all_orgs) {
            for (i = 0; i < this.attributes.all_orgs.length; i++) {
                if (this.attributes.all_orgs[i].id === org_id) {
                    return this.attributes.all_orgs[i];
                }
            }
        }
        return false;
    },

    getNavigableOrgs: function() {
        // just returns all the user's orgs except hidden staff enterprises
        return this.attributes.all_orgs.filter(o => (o.capabilities ?? '').indexOf('k') === -1 ||
                                                    o.temporary === true ||
                                                    o.role=== "certified_ent_builder" );
    },

    getOrgIdWithParentId: function(orgId) {
        /* Returns an Array of the given org ID, including its parent if applicable. */
        let parentOrg = this.getOrgById(orgId).parent_organization;
        return parentOrg ? [orgId, parentOrg.id] : [orgId];
    },

    getAllOrgIds: function(){
        var self = this;
        return self.attributes.all_orgs?.map(x => x.id);
    },

    getOrgByAllMemberAccountUsername: function(allMemberUsername) {
        if(this.attributes.orgAccountMap && this.attributes.orgAccountMap[allMemberUsername])
        {
            return this.getOrgById(this.attributes.orgAccountMap[allMemberUsername]);
        }
        return false;
    },

    associateAllMembersAccount:function(account)
    {
        var self = this, orgIdForAccount;

        if(!self.attributes.orgAccountMap)
        {
            self.attributes.orgAccountMap = {};
        }

        orgIdForAccount = _.reduce(account.relationships_info || [], function(memo,relationshipInfo){
           if(relationshipInfo.relationship === "org-member")
           {
               return relationshipInfo.thru_organization;
           }
        },0);

        if(orgIdForAccount && orgIdForAccount > 0)
        {
            self.attributes.orgAccountMap[account.username] = orgIdForAccount;
        }
    },

    setDefaultOrg: function(org) {
        this.setProfileSetting('current_org_id', org.id, true, function() {
            if (org.needs_verification) {
                // redirect to confirm account for this org
                window.location = "/account/confirm";
            }
        });
        this._setPatientProviderAttributes();
    },

    _setPatientProviderAttributes(){
        this.attributes.isProvider = this.isProvider();
        this.attributes.isPatient = this.isPatient();
        this.attributes.isClient = this.isClient();
    },

    getDefaultOrg: function() {
        // gets the default/selected org (or first if there isn't one) that this person is a member of
        var org = this.getOrgById(this.getProfileSetting('current_org_id'));
        if (org) {
            return org;
        }

        // pick a different org, prioritizing "client having orgs",
        // otherwise return an empty object as the server will create a default org for this user
        return this.attributes.all_orgs?.sort((a, b) => b.has_clients - a.has_clients)[0] || {};
    },

    getEnterpriseOrg: function() {
        return this.attributes.orgs?.filter(o => o.supports_child_organizations)?.[0];
    },

    isCurrentOrgStaffAdmin: function(){
        // returns true if you are an admin in the current org, or a global user admin
        return this.getDefaultOrg()?.admin || this.get("user_admin");
    },

    // return an array of custodee usernames
    custodeeUsernames: function() {
        return _.map(this.get('custodees'), function(c) {
            return c.custodee_username;
        });
    },

    getCustodeeById: function(custodee_id) {
        custodee_id = parseInt(custodee_id, 10) || -1;
        if (this.attributes.custodees) {
            for (let i = 0; i < this.attributes.custodees.length; i++) {
                if (this.attributes.custodees[i].custodee_id === custodee_id) {
                    return this.attributes.custodees[i];
                }
            }
        }
        return false;
    },

    matchesInvite: function(inviteName){
        return (inviteName === (this.attributes.firstname + " " + this.attributes.lastname) ||
                inviteName === (this.attributes.lastname + ", " + this.attributes.firstname));
    },

    simpleUIMode: function(){
        return true;
    },

    canStartConversations: function(){
        var self = this;
        if (self.getDefaultOrg().removed) return false;
        return !self._orgFeatureSettingValue("prevent_start_conversation");
    },

    canEditProfile: function(){
        const self = this;
        if (!self.getDefaultOrg() || self.getDefaultOrg().removed) return true;
        return !self._orgFeatureSettingValue("prevent_profile_edit");
    },

    canInviteContacts: function(){
        var self = this;
        if (self.getDefaultOrg().removed) return false;
        return !self._orgFeatureSettingValue("prevent_invite_contacts");
    },

    canCreateGroups: function(){
        var self = this;
        if (self.getDefaultOrg().removed) return false;
        return !self._orgFeatureSettingValue("prevent_create_groups");
    },

    canManageGroups: function(){
        return this.canCreateGroups() || _.find(this.attributes.orgs, function(org){return org.admin;});
    },

    canSeeFiles: function(org) {
        org = org || this.getDefaultOrg();
        return this.isProvider(org) || org.capabilities?.indexOf("b") > -1;
    },

    canSeeContacts: function(org) {
        org = org || this.getDefaultOrg();
        return this.isProvider(org) || org.capabilities?.indexOf("d") > -1;
    },

    canSeePatientLevelATC: function () {
        return !_.find(this.attributes.orgs || [], function (org) {
            return org.extended_props && org.extended_props.hide_target_user_atc;
        });
    },

    shouldScrollToFirstUnreadNote: function () {
        return _.find(this.attributes.client_orgs || [], function (org) {
            return org.extended_props && org.extended_props.web_scroll_to_unread;
        });
    },

    canManageWearables: function () {
        return this._orgFeatureSettingValue("allow_manage_wearables");
    },

    hideSMSDeliveryWarnings: function() {
        const org = this.getDefaultOrg();
        return org?.extended_props?.sms_failure_warnings;
    },

    canAdminOrg: function(orgId)
    {
        let self = this;
        // permissions to edit forms / workflows include org props changes in the UI
        return self.canEditFormsAndWorkflows(orgId);
    },

    canEditFormsAndWorkflows: function(orgId)
    {
        let self = this,
            org = orgId ? self.getOrgById(orgId) : self.getDefaultOrg();
        if(org.supports_child_organizations && org?.role === "certified_ent_builder"){
            return true;
        }
        return self.get("user_admin") || org?.role === "certified_builder";
    },

    canTempAdminEnterprisePractices: function(){
        return (this.attributes.orgs || []).filter(
            o=>o.supports_child_organizations && o.role==="certified_ent_builder").length > 0;
    },

    canEditPatientInfo: function(contact) {
        const org = this.getDefaultOrg();
        const permissions = org.extended_props.edit_patient_demographics;

        if (!contact.hasRelationshipInContextOfOrgId("patient", org.id)) {
            // has to be a patient in this org
            return false;
        }

        if (App.account.get("user_admin")) {
            return true;
        } else if (permissions === "disabled") {
            return false;
        } else if (permissions == "staff") {
            return this.isProvider() || org.admin;
        } else {
            return org.admin;
        }
    },

    canStartWorkflows: function() {
        const self=this, org = this.getDefaultOrg();
        // if you are an admin in the org / can edit workflows, you can launch them
        if(org.admin || self.canEditFormsAndWorkflows()){
            return true;
        }
        // otherwise the org can have the "prevent starting workflows" feature setting turned on
        return !org?.extended_props?.feature_settings?.prevent_start_workflows_staff;
    },

    setLocaleName: function(localeName){
        // temporarily/locally establish a locale for this user
        if(localeName)
        {
            if(localeName.split("-").length > 1)
            {
                this.attributes.locale = localeName.split("-")[0];
            }
            else
            {
                this.attributes.locale = localeName;
            }
        }
        else
        {
            this.attributes.locale = "en";
        }
    },

    _orgFeatureSettingValue: function(setting){
        const self=this, currentOrg = self.getDefaultOrg();
        var foundSetting = false;
        _.each([["orgs","staff"],["client_orgs","clients"]], function(orgTypes){
            let orgList = (self.attributes[orgTypes[0]] || []).filter(o => !currentOrg || (o.id === currentOrg.id));
            _.each(orgList, function(org){
                if (org.extended_props && org.extended_props.feature_settings && !foundSetting)
                {
                    foundSetting = org.extended_props.feature_settings[setting + "_" + orgTypes[1]];
                    return false;
                }
            });
        });
        return foundSetting;
    },

    getSplashConfig: function(){
        var splashConfig, self=this;

        _.each([["orgs","staff_splash"],["client_orgs","client_splash"]], function(orgTypes){
            _.each(self.attributes[orgTypes[0]] || [], function(org){
                if(org.extended_props && org.extended_props[orgTypes[1]])
                {
                    splashConfig = org.extended_props[orgTypes[1]];

                    if(splashConfig["slides_" + self.get("locale")])
                    {
                        splashConfig.slides = splashConfig["slides_" + self.get("locale")];
                    }

                }
            });
        });

        return splashConfig;
    },

    toJSONForUpdate: function(){
        //gets only params that are necessary for an account update
        var data = {};
        var self = this;
        _.each(["email","firstname","lastname","credentials","phone","dob","gender","visible_to_public","visible_to_network","primaryroles"],
            function(key){
                data[key] = self.get(key) || "";
        });
        return data;
    },

    clickToCallOrgId: function(){
        var self = this,
            clickToCallOrg = _.find(self.attributes.orgs || [], function(org){
            return org.extended_props && org.extended_props.allow_click_to_call
        });

        if(clickToCallOrg)
        {
            return clickToCallOrg.id;
        }

    }
});

export default Account;