import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'

// global container for the app
window.App = {
    _eventBus: _.extend({}, Backbone.Events),
    _events: [],
    _max_lock_retries: 10,
    _max_clickstream_length: 1000,
    _clickstream: {events:[], errors:[]},

    bind: function(event, callback, context) {
        // add event to list so we can unbind on close
        this._events.push({
            'event': event,
            'callback': callback,
            'context': context
        });
        return this._eventBus.bind(event, callback, context);
    },

    unbind: function(event, callback, context, retry_num) {
        var self = this;
        // get a lock on the events list; if we can't, set a timeout to try again
        // REVIEW: I don't think this mutex is necessary, as client-side Javascript is
        // single-threaded, but other than making the code more complicated, it can't hurt.
        if(!this._events.lock && ((this._events.lock = true))) {
            // remove this event from the list
            var newEvents = [];
            var i;
            for(i = 0; i < this._events.length; i++) {
                var evt = {
                    'event': event,
                    'callback': callback,
                    'context': context
                };
                if(this._events[i] !== evt) {
                    newEvents.push(this._events[i]);
                }
            }
            this._events = newEvents;
            this._events.lock = false;
            return this._eventBus.unbind(event, callback, context);
        }
        // try again
        retry_num = retry_num || 0;
        if(retry_num < this._max_lock_retries) {
            window.setTimeout(function(event, callback, context, retry_num) {
                self.unbind(event, callback, context, retry_num);
            }, 0, event, callback, context, retry_num + 1);

        }
    },

    // unbind all events with the given context
    unbindContext: function(context, retry_num) {
        var self = this;
        var i;
        if(!this._events.lock && (this._events.lock = true)) {
            var newEvents = [];
            for(i = 0; i < this._events.length; i++) {
                var evt = this._events[i];
                if(evt.context === context) {
                    this._eventBus.unbind(evt.event, evt.callback, evt.context);
                }
                else {
                    newEvents.push(evt);
                }
            }
            this._events = newEvents;
            this._events.lock = false;
            return this;
        }
        // try again
        retry_num = retry_num || 0;
        if(retry_num < this._max_lock_retries) {
            window.setTimeout(function(context, retry_num) {
                self.unbindContext(context, retry_num);
            }, 0, context, retry_num + 1);

        }
    },

    trigger: function() {
        return this._eventBus.trigger.apply(this._eventBus, arguments);
    },


    // log an event to the clickstream
    logClickstream: function(eventName) {
        var eventData = {};
        if (arguments.length > 1) {
            eventData = Array.prototype.slice.call(arguments, 1)[0];
        }
        setTimeout(function(){
            // don't add to clickstream if we're maxed out
            if (App._clickstream.events.length < App._max_clickstream_length) {
                var now = new Date().getTime();
                App._clickstream.start = App._clickstream.start || now;
                var elapsedTime = now - App._clickstream.start;
                App._clickstream.events.push([elapsedTime, eventName, eventData]);
            }
       }, 20);
    },

    // log an error to the clickstream
    // errors are kept separately to make it faster to extract them from the logs
    logError: function(evt) {
        var message, file, line;
        if (evt.type === "error") {
            message = evt.message;
            file = evt.filename;
            line = evt.lineno;
        } else if (arguments.length === 3) {
            var args = Array.prototype.slice.call(arguments);
            message = args[0];
            file = args[1];
            line = args[2];
        } else {
            message = evt;
        }
        App._clickstream.errors.push([message, file, line]);
        var errNo = App._clickstream.errors.length - 1;
        App.logClickstream("error", errNo);
    },

    // return the contents of the clickstream
    getClickstream: function() {
        return App._clickstream;
    },

    // reset the clickstream
    resetClickstream: function() {
        App._clickstream = {events:[], errors:[], start: new Date().getTime()};
    },

    // allows the app to inject certain functionality (e.g. broadcast some other event, like closing tooltips)
    // when someone else needs a real event (e.g. click, mouseleave) to stop propagating
    stopPropagation: function(evt) {
        // first, do what the developer wanted
        evt.stopPropagation();
        // now broadcast to any interested parties
        if(evt.type === "click"){
            this.trigger("events:click_event_stopped",evt);
        }
    },

    // for now this is a no-op - nothing we need to inject or do when a dialog opens (DialogView handles everything)
    openDialogView: function(dialogView) {
        return dialogView;
    },

    isDesktop: function() {
        return !this.isTouch();
    },

    isTouch: function() {
        return this.config.get('isTouch');
    },
    
    Events: {
        // Attachments
        GET_ATTACHMENT: "app:get_attachment",
        
        // Workflow Steps
        CANCEL_WORKFLOW_STEP_FORM: "app:workflow_step_form_cancel",
        WORKFLOW_DETAILS_CLOSED: "app:workflow_details_closed"
    }
};

// global error listener
window.addEventListener('error', e => {
    // Get the error properties from the error event object
    const { message, filename, lineno } = e;
    App.logError(message, filename, lineno);
});


// in case we left a console.log stmt around and you are on IE < 9
if(typeof(console) === "undefined"){
    window.console = {
        log : function(){
            return;
        }
    };
}

// Delegate .transition() calls to .animate()
// if the browser can't do CSS transitions.
if (!$.support.transition)
{
    $.fn.transition = $.fn.animate;
}


// extend backbone views to have a close method / onClose handler
Backbone.View.prototype.close = function() {
    let isParentDefined = this.$el.parent() !== null;

    if (this.shouldCloseView && isParentDefined) {
        // Only prompt the user about Unsaved Changes if the element
        // still exists in the DOM. If the element has already been
        // removed, like when the user has clicked the browser's back
        // button, then skip over showing the error. Ideally, we could 
        // catch a back button event and prevent data loss in that scenario,
        // but it doesn't seem feasible in our current app architecture.
        let existsInDOM = document.body.contains($(this.$el)[0]);
        if (existsInDOM && !this.shouldCloseView()) {
            return;
        }
    }
    
    this.remove();

    this.isViewClosing = true;

    if(this.onClose) {
        this.onClose();
    }
    if(this.options.onCloseCallback){
        this.options.onCloseCallback();
    }
};

Backbone.View.prototype.remove = function() {
    this.$el.remove();
    this.stopListening();
    this._detach();
    return this;
};

Backbone.View.prototype.getEl = function() {
    // our App.Dialog base class overrides this because of shenanigans (the $el) is different based on whether
    // it is opened as a dialog vs a "pushed" view. Regardless, we allow regular `Backbone.View` subclasses to
    // use this.getEl() for consistency.
    return this.$el;
};

Backbone.View.prototype._detach = function(){
    this.unbind();
    // close anything in _childViews
    if(this._childViews) {
        this.removeAllChildViews();
    }
    // unbind all App.eventBus events with this context
    App.unbindContext(this);
};

// places a reference to a child view in the parent view, which then allows auto-closing in the close method above
// returns the childView - e.g. this.someview = this.addChildView(new App.View(...));
Backbone.View.prototype.addChildView = function(childView) {
    // if the parent view is closing, prevent adding child views (immediately close the child and return)
    if(this.isViewClosing){
        childView.close();
        return childView;
    }

    if(!this._childViews) {
        this._childViews = [];
    }
    this._childViews.push(childView);
    return childView;
};

// no op function
Backbone.View.prototype.openDialogView = function(childDialogView) {
    return App.openDialogView(childDialogView);
};

Backbone.View.prototype.removeChildView = function(childView) {
    var self = this;
    if(self._childViews){
        _.each(self._childViews,function(val, idx){
            if(val === childView){
                self.removeChildViewAt(idx);
            }
        });
    }
};

Backbone.View.prototype.removeChildViewAt = function(idx) {
    if(this._childViews && this._childViews.length > idx){
        this._childViews[idx].close();
        this._childViews.splice(idx,1);
    }
};

Backbone.View.prototype.removeAllChildViews = function() {
    var self = this;
    if(self._childViews){
        _.each(self._childViews,function(val){
            val.close();
        });
        self._childViews = [];
    }
};

// extend backbone views to have a hide/show methods / handlers
Backbone.View.prototype.hide = function() {
    this.$el.hide();
    if(this.onHide) {
        this.onHide();
    }
};

Backbone.View.prototype.show = function() {
    this.$el.show();
    if(this.onShow) {
        this.onShow();
    }
};

Backbone.View.prototype.disable = function() {
    this.$el.addClass("view_disabled");
    if(this.onDisable) {
        this.onDisable();
    }
};

Backbone.View.prototype.enable = function() {
    this.$el.removeClass("view_disabled");
    if(this.onEnable) {
        this.onEnable();
    }
};


let originalSetEl = Backbone.View.prototype.setElement;

Backbone.View.prototype.setElement = function(element) {
    if (this.el !== element && $(this.el).data("backboneView")) {
        $(this.el).removeData("backboneView");
    }
    $(element).data('backboneView', this);
    $(element).attr('data-backbone-view-id', this.cid);
    return originalSetEl.apply(this, arguments);
};

// Compatibility override - Backbone 1.1 got rid of the 'options' binding
// automatically to views in the constructor - we need to keep that.
Backbone.View = (function(View) {
   return View.extend({
        constructor: function(options) {
            this.options = _.defaults(options || {}, this.options || {});
            View.apply(this, arguments);
        }
    });
}(Backbone.View));
