const AccountRoleType = {
    CAREGIVER: 'i',
    CLINICIAN: 'c',
    PATIENT:   'p',
    ROLE:      'r',
    SERVICE:   'x',
    STAFF:     's',
    TEAM:      'g',
    USER:      'u'
};

/**
 * Determines if the given account is a staff member.
 *
 * @param {Account} account - The account object to check.
 * @returns {boolean} - True if the account is a staff member, false otherwise.
 */
export function isStaff(account) {
    return accountTypeContainsValue(account, AccountRoleType.STAFF);
}

/**
 * Determines if the given account is a team.
 *
 * @param {Account} account - The account object to check.
 * @returns {boolean} - True if the account is a team, false otherwise.
 */
export function isTeam(account) {
    return accountTypeContainsValue(account, AccountRoleType.ROLE);
}

function accountTypeContainsValue(account, value) {
    let accountType = null;
    if (typeof account.get === 'function') {
        accountType = account.get('accounttype');
    }
    else {
        accountType = account.accounttype;
    }

    return accountType && accountType.indexOf(value) > -1;
}
