import $ from 'jquery'
import Backbone from 'backbone'
import _ from 'underscore'
import Contact from 'app/models/contact'

import {
    formatNote,
    isTargeted,
    participantsToList,
    splitParticipants,
    splitTags,
} from 'util/twistle'

import { timeAgoInWords } from 'util/date'
import { localize } from 'util/localize'

// Model encapsulating a Conversation
App.Conversation = Backbone.Model.extend({
    idAttribute: "convseq",
    lastNoteId: undefined,
    NOTEROLES: {
        TO_ME: ['ta', 't'],
        FYI: ['-t']
    },
    defaults: {
        //"accounts": {}
    },

    url: function() {
        return "/conversation/View?convseq=" + this.get("id") + "&how_many=50&lastnote=" + (this.lastNoteId || "");
    },

    initialize: function() {
        this.bind('change:participantInfo', this.updateParticipantString);
    },

    sync: function(method, model, options, store) {
        var self = this;

        // don't fetch data when dummy data is provided
        if (self.isDummyData()) {
            setTimeout(options.success, 0);
            return;
        }

        Backbone.Model.prototype.sync.apply(this, arguments);
    },

    fetchLatest: function(opts) {
        var self = this;
        var notes = self.attributes.notes;

        if(!opts.forceAfterNoteId && notes && notes.length > 0 && !self.attributes.isNew && notes.length >= Math.min(50,parseInt(self.attributes.totalmessages,10)))
        {
            // ask for everything after the newest server note we have - unless we only have the first note and it's got fwd'd attachments
            var i;
            for(i=notes.length-1;i>=0;i--)
            {
                if(!notes[i].pending_post_to_server && !(self.attributes.relatedConversation && i===0))
                {
                    opts.fetchAfterNoteId = notes[i].id;
                    break;
                }
            }

        }

        if(opts.fetchAfterNoteId)
        {
            // save for future calls
            self.lastLatestNoteId = opts.fetchAfterNoteId;
            self.lastNoteId = self.lastLatestNoteId;
        }

        if(!opts.fetchAfterNoteId && self.lastLatestNoteId && !opts.forceRefresh)
        {
            opts.fetchAfterNoteId = self.lastLatestNoteId;
        }

        self.fetch(opts);
    },

    fetchOlder: function(callBack) {
        var self = this, earliestNoteId = 0;

        if(self.attributes.notes && self.attributes.notes.length > 0)
        {
            var firstNote = self.attributes.notes[0];
            if(firstNote)
            {
                earliestNoteId =firstNote.id || 0;
            }
        }

        self.lastLatestNoteId = undefined;
        self.lastNoteId = undefined;

        var opts = {
                priorToNoteId :earliestNoteId,
                data:$.param({
                    how_many:50,
                    start_before_note_id : earliestNoteId
                             }),
                success:callBack,
                failure:callBack
                };

        self.fetch(opts);
    },

    parse: function(response) {
        var self = this;

        if (response && response.conversations) {
            if (response.conversations.length > 0) {
                var responseData = response.conversations[0];
                responseData.accounts = response.accounts;

                if(!responseData.participantstatus)
                {
                    responseData.participantstatus = {}; // not present if we haven't done a full view req
                    _.each(_.keys(responseData.accounts),function(username){responseData.participantstatus[username]="";});
                }


                responseData.connectionRequests = response.connection_requests;
                responseData.custodees = response.custodees;
                responseData.pendingOrgMemberRequestCount = response.pending_org_member_request_count;
                responseData.participantList = participantsToList(responseData.participants);

                self._updateAccountData(response.accounts, responseData.participantList);

                responseData.tagInfo = splitTags(responseData.tags);

                responseData.participantInfo = splitParticipants(responseData.participants, self.get("accountList"));

                // if lastNoteId is set, append the notes we got back
                // otherwise self.notes = whatever we got back
                // take care to not end up w/ duplicate notes
                if (self.lastNoteId || self.lastLatestNoteId) {
                    var newNoteIds = _.pluck(responseData.notes||[],"id");
                    var newTempIds = _.pluck(responseData.notes||[],"localTempId");
                    responseData.notes = _.filter(self.attributes.notes, function(note){
                        return (!_.contains(newNoteIds, note.id) && !_.contains(newTempIds, note.localTempId));
                    }).concat(responseData.notes);
                }

                // process the conversation notes
                _.each(responseData.notes, function(note) {
                    note.participantInfo = splitParticipants(note.participants, self.get("accountList"));
                    note.authorInfo = self.get("accountList").get(note.author_id);
                    note.elapsedDate = timeAgoInWords(note.date);
                    note.formattedSummary = formatNote(note.summary);

                    if(!note.localTempId) // if we don't have persistence
                    {
                        note.localTempId = note.id;
                    }

                });

                responseData.is_welcome = (responseData.participantList.length === 2 &&
                    _.indexOf(responseData.participantList, "support") > -1 &&
                    responseData.subject.indexOf("Welcome To Twistle") > -1 &&
                    ((responseData.creator_id || responseData.creator).toLowerCase() === "support"));

                self.attributes = responseData;

                return responseData;
            }
        } else {
            return response;
        }
    },

    getNewNotes:function()
    {
        var self = this;
        if (self.lastNoteId || self.lastLatestNoteId) {
            // slice to only stuff newer than the last note id
            return _.filter(self.attributes.notes, function(note)
                {
                    return parseInt(note.id,10) > (parseInt(self.lastNoteId,10) || parseInt(self.lastLatestNoteId,10));
                });
        }

        return self.attributes.notes || [];

    },

    conversationBanner: function () {
        // select all notes with banners
        var self = this, bannerNotes = _.filter(self.attributes.notes || [], function (n) {
            return _.keys(n.banner_props).length;
        });

        // since the banner props might exist on multiple notes in this conversation,
        // we need the "latest" rendition
        var props = {};
        if (bannerNotes.length > 0) {
            props = _.clone(_.last(bannerNotes).banner_props);
        }

        // add sensible default foreground and background colors if they are missing
        props.text_color = props.text_color || "#000000";
        props.background_color = props.background_color || "#FFFFFF";

        return props;
    },

    _updateAccountData: function(accounts, currentParticipants){
        var self = this,
            participantList = self.attributes.participantList || currentParticipants || [];

        // if some of our accounts are members of orgs that are "in" the conversation, mark them as such
        self.attributes.orgInConversationIdList = _.map(_.filter(participantList, function(u){
                                                            var p = accounts[u];
                                                            return p &&  p.accounttype === "s";}
                                                                ),
                                                        function(u){
                                                            var p = accounts[u];
                                                            return p && p.relationships_info[0] && p.relationships_info[0].thru_organization;
                                                        });


        _.each(accounts, function(a){
            if (a.accounttype !== "s"){
                var r = _.find(a.relationships_info, function(r){return r.relationship === "org-member" &&
                                    _.contains(self.attributes.orgInConversationIdList, r.thru_organization);});
                a.memberOfOrgInConversation = (r && r.thru_organization) || false ;
            }
        });

        self.attributes.accountList = App.AccountList.fromHash(accounts);
        self.attributes.contactList = App.ContactList.fromHash(accounts);
        self.attributes.accounts = accounts;
        self.attributes.isClosedToReply = self.isClosedToReply();
    },

    addOrRemoveAccount: function(contact, skipRemove, createStatus, accountDataOnly){
        // returns whether the account was present, and optionally can skip removing
        var self = this, wasPresent = false;
        if (!self.attributes.accounts){
            self.attributes.accounts = {};
        }
        if (!self.attributes.participantList){
            self.attributes.participantList = [];
        }


        if (self.attributes.accounts[contact.get("username")]){
            if (!skipRemove){
                delete self.attributes.accounts[contact.get("username")];
            }
            wasPresent = true;
        }
        else{
            self.attributes.accounts[contact.get("username")] = contact.toJSON();
        }

        if(!accountDataOnly)
        {
            if(_.contains(self.attributes.participantList, contact.get("username"))){
                if (!skipRemove){
                    self.attributes.participantList = _.without(self.attributes.participantList, contact.get("username"));
                }
            }
            else{
                self.attributes.participantList.push(contact.get("username"));
            }
        }


        if(createStatus && !self.attributes.participantstatus)
        {
            self.attributes.participantstatus = {};
        }
        if(createStatus)
        {
            self.attributes.participantstatus[contact.get("username")] = {};
        }


        self._updateAccountData(self.attributes.accounts);
        return wasPresent;
    },

    getNoteById: function(noteId)
    {
        var self = this;
        return _.findWhere(self.attributes.notes, {id:noteId});
    },

    removeAccountByUsername: function(username)
    {
        var self = this;
        self.attributes.participantList = _.reject(self.attributes.participantList, function(p){return p === username;});
        self.attributes.accounts = _.omit(self.attributes.accounts,username);
        self._updateAccountData(self.attributes.accounts);
    },

    setDefaultTargets:function(usernameList)
    {
        var self = this;
        self.attributes.default_targets = usernameList || [];
    },

    setRelatedConversation:function(relatedTo, messagesToForward)
    {
        var self = this;
        self.attributes.relatedConversation = relatedTo.get("convseq");
        self.attributes.relatedConversations = [relatedTo.toJSON()];
        self.attributes.messagesToForward = messagesToForward;
    },

    getRelatedConversationData:function()
    {
        var self = this, relatedConv;

        _.each(self.attributes.relatedConversations || [], function(conv){
            if(conv.convseq === self.attributes.relatedConversation)
            {
                relatedConv = conv;
                return false;
            }
        });
        return relatedConv;
    },

    getAboutUser: function() {
        if (this.attributes.accounts && this.attributes.aboutuser_id) {
            return new Contact(this.attributes.accounts[this.attributes.aboutuser_id]);
        }
    },

    setAboutUser: function(contact) {
        if (!this.attributes.accounts) {
            this.attributes.accounts = {};
        }

        if (contact) {
            this.attributes.aboutuser = contact.get('username');
            this.attributes.aboutuserformalname = contact.get('formalname');
            if (!_.has(this.attributes.accounts, this.attributes.aboutuser)) {
                this.attributes.accounts[this.attributes.aboutuser] = contact.toJSON();
            }
        } else {
            var currentAbout = this.attributes.aboutuser;
            this.attributes.aboutuser = undefined;
            this.attributes.aboutuserformalname = undefined;

            // remove prior user from accounts if they aren't a participant
            if(currentAbout && !_.contains(this.attributes.participantList || [], currentAbout)) {
                this.attributes.accounts = _.omit(this.attributes.accounts, currentAbout);
            }
        }

    },

    updateParticipantString: function() {
        // build a participant string
        var participantInfo = this.get("participantInfo") || {};
        var targets = _.map(participantInfo.targets, function(user) {
            return user.get('username') + "*";
        });

        var others = _.map(participantInfo.others, function(user) {
            return user.get('username');
        });


        this.set("participants", targets.concat(others).join(","));
    },

    addNote: function(note) {
        // update the noteroles on this conversation based on whether the note is targeted to us
        var noteroles = _.clone(this.get('noteroles'));
        if (isTargeted(note.participants, App.account.get('username'))) {
            noteroles = _.union(noteroles, this.NOTEROLES.TO_ME);
            noteroles = _.without(noteroles, this.NOTEROLES.FYI);
        } else {
            noteroles = _.without(noteroles, this.NOTEROLES.TO_ME);
            noteroles = _.union(noteroles, this.NOTEROLES.FYI);
        }
        this.set('noteroles', noteroles);
    },

    connectionRequests: function() {
        return this.get("connectionRequests") || [];
    },

    orgMemberRequestCount: function() {
        return this.get("pendingOrgMemberRequestCount") || 0;
    },

    hasPendingConnectionRequests: function() {
        return this.connectionRequests().length > 0 || this.orgMemberRequestCount() > 0;
    },

    hasPatientParticipants: function(){
        var self = this;
        return self.getPatientParticipantUsernameList().length > 0;
    },

    targetActorCanTerminateWf: function() {
        const self = this;
        return !!self.attributes.target_actor_can_terminate_wf;
    },

    getWorkflowExecutionId: function() {
        const self = this;
        return self.attributes.workflow_execution_id;
    },

    getUnsubscribeFromWfText: function() {
        const self = this;
        return self.attributes.unsubscribe_from_wf_text || localize("ConversationDetailScreen-patientUnsubscribe", "Are you sure you want to unsubuscribe?");
    },

    getPatientParticipantUsernameList: function(){
        var self = this;
        return _.filter(self.attributes.participantList || [], function(participant) {
            const contactData = self.attributes.accounts[participant];

            if (contactData) {
                const c = new Contact(contactData);
                if((c.isPatient() || c.hasRelationship("patient"))
                        && !c.get("accountIsSelf"))
                {
                    return true;
                }
            }
        });
    },


    isConnectionRequest: function() {
        return this.get("is_connection_req");
    },

    isNew: function() {
        return this.get("isNew");
    },

    isAcceptedConnectionRequest: function() {
        // make sure we don't have any pending connection requests
        if (this.get("is_connection_req") && !this.hasPendingConnectionRequests()) {
            return this.connectionRequestInfo().accepted;
        }
        return false;

    },

    isDummyData: function() {
        return !!this.get("dummy");
    },

    // if this is a connection request, return useful information about it
    connectionRequestInfo: function() {
        var self = this, fromUser, toUser;
        if (!self.isConnectionRequest()) {
            return;
        }
        var participantInfo = self.get('notes')[0].participantInfo;
        var contactA = participantInfo.others[0];
        var contactB = participantInfo.targets[0];

        if(!contactA || !contactB)
        {
            // we lost access to the contact in this connection request
            return;
        }

        var userA = contactA.toJSON(), userB = contactB.toJSON();

        if(userA.username === self.attributes.creator)
        {
            fromUser = userA;
            toUser = userB;
        }
        else
        {
            fromUser = userB;
            toUser = userA;
        }

        const contact = new Contact();
        var connectWithUser; // other user in connection
        var connectOrgUser; // if I'm the recipient, we look at my own relationships, otherwise we look at the recipient's
        var myUser = self.get('accounts')[App.account.get('username')];
        var forMe = false; // connection request was sent to me
        var needsAction = false;

        // if for some reason we can't calculate from / to
        if(!fromUser || !toUser){
            return;
        }

        if (fromUser.username === App.account.get('username')) {
            connectWithUser = self.get('accounts')[toUser.username];
            connectOrgUser = connectWithUser;
        } else {
            connectWithUser = self.get('accounts')[fromUser.username];
            connectOrgUser = myUser;
            forMe = true;
        }

        if (!connectWithUser || !connectOrgUser) {
            return undefined;
        }

        // org membership request
        var connectionRelationship = _.find(connectOrgUser.relationships_info, function(r) {
            return r.thru_username === "self" && r.invite_conversation_id === self.get('id');
        });

        var connectionThruOrganization;
        if (!connectionRelationship) {
            // look for a connection relationship either through ourselves or our custodees, or through an
            // matching invite_conversation
            connectionRelationship = _.find(connectWithUser.relationships_info, function(r) {
                return r.invite_conversation_id === self.get('id');
            });
            // relevant for client -> provider relationships
            connectionThruOrganization = connectWithUser.organization;
        }

        if (connectionRelationship && connectionRelationship.relationship === "care giver for"){
            // if this is a custodian inviting a provider to a custodee circle, do a switcheroo
            connectionRelationship.connectViaCareGiver = connectWithUser.formalname;
            connectWithUser = self.get('accounts')[connectionRelationship.thru_username];
            connectionRelationship.relationship = "patient";
        }

        if (!connectionRelationship) {
            return undefined;
            //connectionRelationship = {};
        }

        if (this.attributes.status !== "clear") {
            needsAction = forMe && !connectionRelationship.accepted;
        }

        contact.attributes = connectWithUser;

        // if we can't deref from/to
        if(!connectWithUser)
        {
            return;
        }

        return {
            from: fromUser,
            to: toUser,
            connectionThruOrganization: connectionThruOrganization,
            connectionRelationship: connectionRelationship,
            relationship: connectionRelationship.relationship,
            connectWithFormalName: connectWithUser.formalname,
            connectWithShortName: connectWithUser.shortname,
            connectWithUser: connectWithUser,
            relationshipTitle: contact.formatRelationship(connectionRelationship.relationship, (connectionThruOrganization && connectionThruOrganization.id)),
            isAccepted: connectionRelationship.accepted,
            isDeclined: connectionRelationship.declined,
            isOrgMemberRequest: connectionRelationship.relationship === "org-member",
            isForMe: forMe,
            needsAction: needsAction
        };
    },

    isToMe: function() {

        return _.indexOf(["foreground","clear"], this.attributes.status) > -1;

    },

    canMoveToForeground:function(){
        // simple UI people can't move things foreground <> background
        return this.attributes.status !== "foreground" &&
            (!App.account.simpleUIMode() || this.attributes.status !== "background");
    },

    canMoveToBackground:function(){
        return !App.account.simpleUIMode() && this.attributes.status === "foreground";
    },

    canMoveToClear:function(){
        return this.attributes.status !== "clear";
    },

    markUnread: function() {
        App.trigger("app:unread_count_increment");
        this.set("unread", true);
        return this;
    },

    // mark this conversation and all its notes as read
    markRead: function() {
        const self = this;
        if(!self.isConnectionRequest()){
            App.trigger("app:unread_count_decrement");
        }
        self.set("unread", false);
        self.set('readmessages', this.get('totalmessages'));
        let notes = self.get('notes') || [], i;
        for (i = 0; i < notes.length; i++) {
            notes.readflag = true;
        }
        return self;
    },

    isUnread: function(){
        var self = this;
        if (self.isConnectionRequest()){
            var info = self.connectionRequestInfo();
            return (info && self.isToMe() && this.attributes.status === "foreground") || false;
        }
        return self.get("unread");
    },

    isClosedToReply: function() {
        return this.get("is_closed") && App.account.isPatient();
    },

    isReadOnly: function(){
        var self = this,
            username = App.account.get("username"),
            participantList = self.attributes.participantList || [];

        // is it closed and you are a patient?
        if(self.isClosedToReply())
        {
            return true;
        }

        // return false if you are an explicit participant
        if (_.indexOf(participantList, username) > -1){
            return false;
        }

        // return true if we can't find any orgs/team roles in the conversation that you are a member of
        return !_.any(participantList, function(username){
            var account = self.attributes.accounts[username];
            if(account)
            {
                if(account.accounttype === "s" && account.relationships_info[0] &&
                    App.account.getOrgById(account.relationships_info[0].thru_organization))
                {
                    return true; // this is an all-members account of an org you are a member of
                }

                if(account.accounttype === "r" && _.find(account.relationships_info, function(rInfo){
                        return rInfo.relationship === "onteam" && _.contains(["self",App.account.get("username")],
                                rInfo.thru_username);
                    }))
                {
                    return true; // there is a role in the conversation, and you are a member!
                }

                if(account.accounttype === "r" && self.attributes.coverage_role_group &&
                    self.attributes.coverage_role_group.role_group.username === account.username &&
                    self.attributes.coverage_role_group.active
                )
                {
                    // you are/were covering for this role, and it's active
                    return true;
                }

            }

        });
    },

    getShortUrls: function() {
        var self = this,
            shorten_urls = false;
        if (self.attributes.short_urls) {
            // Check the orgs on the conversation
            _.each(self.attributes.mapped_orgs, function(mapped_org) {
                let org = App.account.getOrgById(mapped_org.org_id);
                if (org && org.extended_props && org.extended_props.web_ui_short_urls) {
                    shorten_urls = true;
                }
            });

            // Check account's client orgs
            if (!shorten_urls) {
                _.each(App.account.attributes.client_orgs, function(org) {
                    if (org && org.extended_props && org.extended_props.web_ui_short_urls) {
                        shorten_urls = true;
                    }
                });
            }
        }

        return shorten_urls ? self.attributes.short_urls : {};
    },

    isRepliedTo: function () {
        // returns whether the current user (or a role they are a member of) was the last to author a message in this
        // conversation
        var self = this,
            lastNoteAuthorUsername = (_.last(self.get("notes") || []) || {}).author_id,
            currenUserRoleUsernameList = _.pluck(App.account.currentRoles() || [], "username");

        return (lastNoteAuthorUsername === App.account.get("username")
            || _.contains(currenUserRoleUsernameList, lastNoteAuthorUsername));
    },

    canAccessConversationFromWorkflowTracker: function(workflowExecutionData) {
        const self = this;
         // by default, we show "access conversation" button to open and reply to conversation if you are
        // a participan with a "status" record (which means you've actually been added to it)
        let showAccessConv = (!self.isReadOnly() &&
            _.has(self.get("participantstatus") || {}, App.account.get("username")));
        let addStaffRoleParticipant = undefined;

        if(!showAccessConv &&
            workflowExecutionData.workflow_definition.allow_staff_to_add_self_to_conversations &&
            !self.isReadOnly()){
            // if the `allow_add_staff_to_conversations` setting is on for the org, you can inject yourself
            // into the conversation if you are a member of a role that is in the conversation
            // (see `Conversation.isReadOnly()` for how this is determined)
            showAccessConv = true;
            App.account.currentRoles().forEach(role => {
               if(self.get("participants").indexOf(role.username) > -1){
                   addStaffRoleParticipant = role.username;
               }
            });

        }
        return [showAccessConv, addStaffRoleParticipant];

    }

});
