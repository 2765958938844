import _ from 'underscore'
import Backbone from 'backbone'
import Account from 'app/models/account'

App.AccountList = Backbone.Collection.extend({
    model: Account
});
// generate a new AccountList from the accounts hash we get back from the server
App.AccountList.fromHash = function(accountsHash) {
    var models = [];
    // convert accounts hash to an array
    _.each(accountsHash, function(account) {
        if(account.accounttype === "s")
        {
            App.account.associateAllMembersAccount(account);
        }
        models.push(account);
    });

    return new App.AccountList(models);
};
