import _ from 'underscore'
import Backbone from 'backbone'
import Contact from 'app/models/contact'

App.ContactList = Backbone.Collection.extend({
    model: Contact,
    initialize: function(models, options) {
        this.options = options || {};
    },
    url: function() {
        if (this.options.teamMembersOf) {
            return "/link/List";
        }
        return "/account/Find";
    },

    hasNext: false,
    hasPrev: false,
    parse: function(response) {
        var self = this;
        self.hasNext = response.has_next;
        self.hasPrev = response.has_prev;
        self.totalCount = response.count;

        if (self.options.teamMembersOf) {
            return response;
        }
        _.each(response.accountlist, function(account) {
            if(account.accounttype === "s")
            {
                App.account.associateAllMembersAccount(account);
            }
        });
        return response.accountlist;

    }

});

App.ContactList.fromHash = function(accountsHash) {
    var models = [];
    // convert accounts hash to an array
    _.each(accountsHash, function(account) {
        models.push(account);
    });

    return new App.ContactList(models);
};
