import Backbone from 'backbone'
import _ from 'underscore'

const Config = Backbone.Model.extend({
    initialize: function(attribs) {
        this.bind('change', this.updateAttributes);
        this.updateAttributes();

        try {
            if (window.localStorage) {
                this.attributes.localSettings = JSON.parse(window.localStorage.getItem("tws-local-settings") || "{}") || {};
            }
            else {
                this.attributes.localSettings = {};
            }
        } catch (e) {
            this.attributes.localSettings = {};
        }

    },
    updateAttributes: function() {
        let isTouch = this.attributes.isTablet ||
                      this.attributes.isMobile ||
                      ('ontouchstart' in window) ||
                      (window.DocumentTouch && document instanceof DocumentTouch); // eslint-disable-line no-undef
        this.attributes.isTouch = isTouch;
    },

    needsPanningPreventDefault:function(){
        if(window.device && window.device.platform.toLowerCase() === "android" && parseFloat(window.device.version) >= 4.4)
        {
            return true;
        }
        return false;
    },


    getLocalSetting:function(key){
        return this.attributes.localSettings[key];
    },

    removeLocalSetting:function(key){
        if(this.attributes.localSettings[key]){
            delete this.attributes.localSettings[key];
        }
        this.persistLocalSettings();
    },

    setLocalSetting:function(key, value)
    {
        this.attributes.localSettings[key] = value;
        this.persistLocalSettings();
    },

    clearLocalSettings:function()
    {
        // care for baseURL not being cleared, and tutorial views
        // by filtering out of existing settings and then persisting as new
        var keepVars = ["baseURL", "has_seen_tutorial_"], keepVarData = {};

        _.each(this.attributes.localSettings, function (val, key)
        {
            _.each(keepVars, function (matchKey)
            {
                if (key.startsWith(matchKey)){
                    keepVarData[key] = val;
                }
            });

        });

        this.attributes.localSettings = keepVarData;

        this.persistLocalSettings();

        if(window.localStorage)
        {
            window.localStorage.clear();
        }
    },

    canPersistLocalSetting:function()
    {
        return window.localStorage && !this.localstorageDisabled;
    },

    persistLocalSettings:function(){
        var encodedSettings = JSON.stringify(this.attributes.localSettings || {});

        if(window.localStorage)
        {
            try
            {
                window.localStorage.setItem("tws-local-settings", encodedSettings);
            }
            catch(err)
            {
                // probably mobile safari in private browsing mode
                this.localstorageDisabled = true;
            }
        }
    }
});

export default Config;