import { localize } from "util/localize"

/**
 * Localization helper function for Handlebars templates.
 * @param {Object} opts Handlebars context object. Contains a key, `hash`, which will contain the `key`, `value`, `comment` and `priority` values.
 */
export default function (opts) {
    if (!opts) {
        return "LOC ERROR";
    } else if (!opts.hash?.key) {
        if (typeof(opts) === 'string') {
            console.error("LOC ERROR:", opts);
            return opts;
        } else if (typeof(opts) === 'object') {
            const jsonString = JSON.stringify(opts);
            console.error("LOC ERROR:", jsonString);
            return jsonString;
        }
    }

    return localize(opts.hash.key, opts.hash.value, opts.hash);
}