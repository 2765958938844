
import $ from 'jquery'
import _ from 'underscore'
import {
    formatNote,
} from "util/twistle"
import AttachmentDisplayDialogView from 'app/views/attachment_display_dialog_view'

const InviteContentMixin = {
    addInlineAttachment: function(attachments) {
        const attachList = $('<div class="login_attachment_list"></div>');
        _.each(attachments,function(attachment)
        {
            const $attach = $('<div class="login_attachments"></div>');
            const attachViewDialog = new AttachmentDisplayDialogView(
                {attachment: attachment,
                    fullyPreventDialog:true,
                    openInPausedState:true,
                    playerWidth: 290,
                    playerHeight: 290,
                    openInNewWindow: true
                }
            );
            $attach.append(attachViewDialog.$el);
            attachList.append($attach);
        });
        attachList.append('<div class="noteAttachmentClear clearer"></div>');

        return attachList;
    },

    setOriginatorMessage: function(){
        const self = this;

        if (self.options.inviteConversation){
            self.options.inviteConversation.fetch({
                success:function(){
                    let notes = self.options.inviteConversation.get("notes"),
                        summary = (notes[0] && notes[0].summary) || false,
                        messageUi = self.$(".originator_message"),
                        attachmentHtml;

                    if (notes[0]) {
                        attachmentHtml = self.addInlineAttachment(notes[0].attachments);
                    }

                    if(summary) {
                        messageUi.html(formatNote(summary));
                    }
                    if (attachmentHtml) {
                        messageUi.append(attachmentHtml)
                    }
                }
            });
        }
    },
};

export default InviteContentMixin;